// 余额
import { Request } from '@/http/request'

//获取配置
export function getConfiguration (parameter: any) {
  return Request.axiosInstance.post('/web/user-options/get-option-key-value', parameter)
}

//修改配置
export function modifyConfiguration (parameter: any) {
    return Request.axiosInstance.post('/web/user-options/edit', parameter)
  }

export default { getConfiguration,modifyConfiguration}