// 用户当面付，充值
<template>
  <div>
    <el-button class="top-up confirmAdd" @click="topUp">充值</el-button>
    <!-- 弹窗 -->
    <el-form
        label-position="top"
        label-width="100px"
        :model="upform"
        :rules="rules"
        ref="upform"
    >
      <el-dialog
          title="请使用支付宝扫码支付"
          v-model="centerDialogVisible"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          width="20%"
      >
        <div class="titleno">选择充值金额</div>
        <div class="topUp-tag-out">
          <div
              class="topUp-tag"
              v-for="(item, i) in tagList"
              :key="i"
              :class="selectTag == item.id ? 'isSelect' : 'unSelect'"
              @click="selectThis(item)"
          >
            <span>{{ item.title }}</span>
          </div>
        </div>
        <el-dialog
            width="30%"
            title="请使用支付宝扫码支付"
            v-model="innerVisible"
            :destroy-on-close="true"
            append-to-body
            @close="closeDialog"
        >
          <div class="topUp-code-box">
            <setQrcode
                :text="showForm.qrCode"
                :src="imgSrc"
                :width="200"
                :height="200"
                :size="90"
            ></setQrcode>
          </div>
          <!-- <div class="topUp-code-title">￥{{ upFixed((showForm.amount + showForm.totalAmount),2) }}</div> -->
          <div class="topUp-code-title">￥{{ showForm.originalAmount }}</div>
          <div class="flex-nowrap-center-center">
            <div class="poundage">
              <div class="flex-nowrap-space-between">
                <div class="poundage-name">支付宝手续费</div>
                <div class="poundage-val">￥{{ showForm.amount }}</div>
              </div>
              <div class="flex-nowrap-space-between">
                <div class="poundage-name">费率</div>
                <div class="poundage-val">
                  {{ showForm.commissionCharge * 100 }}%
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
        <div v-if="sohwInput == true">
          <el-form-item label="自定义金额(元)" prop="amount">
            <el-input
                v-model="upform.amount"
                placeholder="请输入金额"
                oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <template #append>￥</template>
            </el-input>
          </el-form-item>
        </div>
        <template #footer>
          <el-form-item>
            <el-button class="cancel" plain @click="resetUpForm('upform')"
            >取消
            </el-button
            >
            <el-button class="confirmAdd" @click="submitUpForm('upform')"
            >充值
            </el-button
            >
          </el-form-item>
        </template>
      </el-dialog>
    </el-form>
  </div>
</template>
<script>
import pay from "@/api/web/pay";
import setQrcode from "@/components/setQrcode.vue";
import emitter from "@/utils/bus.js";

export default {
  components: {
    setQrcode,
  },
  data() {
    const validatePrice = (rule, value, callback) => {
      // let reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/  /^(([1-9]{1}\d*)|(0{1}))$/
      let reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
      if (!value) {
        callback(new Error("金额不能为空"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确的格式(保留两位小数；例：0.01)"));
      } else if (value.length > 10) {
        callback(new Error("最多可输入10个字符"));
      } else {
        callback();
      }
    };
    return {
      innerVisible: false,
      centerDialogVisible: false,
      tagList: [
        {
          id: 1,
          title: "100元",
          value: 100,
        },
        {
          id: 2,
          title: "200元",
          value: 200,
        },
        {
          id: 3,
          title: "500元",
          value: 500,
        },
        {
          id: 4,
          title: "1000元",
          value: 1000.0,
        },
        {
          id: 5,
          title: "2000元",
          value: 2000.0,
        },
        {
          id: 6,
          title: "自定义金额",
          value: "",
        },
      ],
      selectTag: 1,
      sohwInput: false,
      link: "",
      imgSrc: "",
      upform: {
        amount: "100",
      },
      showForm: {
        amount: null,
        commissionCharge: null,
        totalAmount: null,
        qrCode: null,
      },
      rules: {
        amount: [{trigger: "blur", validator: validatePrice}],
      },
    };
  },
  mounted() {
    emitter.on("getTarget", (target) => {
      if (target == "ok") {
        this.imgSrc = "ok";
      }
      // this.innerVisible = false
      // this.closeDialog()
      // console.log('关闭弹窗')
    });
    console.log(this.upFixed(0.023333, 2));
  },
  methods: {
    topUp() {
      this.centerDialogVisible = true;
    },
    selectThis(item) {
      this.selectTag = item.id;
      if (item.id == 6) {
        this.upform.amount = "";
        this.sohwInput = true;
      } else {
        this.sohwInput = false;
        this.upform.amount = item.value;
      }
    },
    // 表单提交
    submitUpForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          pay
              .faceToAlipay({
                total_amount: this.upform.amount,
              })
              .then((res) => {
                this.showForm = res.data;
                this.innerVisible = true;
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetUpForm(formName) {
      this.centerDialogVisible = false;
      this.imgSrc = "";
    },
    upFixed(num, fix) {
      // num为原数字，fix是保留的小数位数
      let result = "0";
      if (Number(num) && fix > 0) {
        // 简单的做个判断
        fix = +fix || 2;
        num = num + "";
        if (/e/.test(num)) {
          // 如果是包含e字符的数字直接返回
          result = num;
        } else if (!/\./.test(num)) {
          // 如果没有小数点
          result = num + `.${Array(fix + 1).join("0")}`;
        } else {
          // 如果有小数点
          num = num + `${Array(fix + 1).join("0")}`;
          let reg = new RegExp(`-?\\d*\\.\\d{0,${fix}}`);
          let floorStr = reg.exec(num)[0];
          if (+floorStr >= +num) {
            result = floorStr;
          } else {
            let floorNumber = +floorStr + +`0.${Array(fix).join("0")}1`;
            let point = /\./.test(floorNumber + "") ? "" : ".";
            let floorStr2 = floorNumber + point + `${Array(fix + 1).join("0")}`;
            result = reg.exec(floorStr2)[0];
          }
        }
      }
      return result;
    },
    // 关闭弹窗清除二维码
    closeDialog() {
      let codeHtml = document.getElementById("qrCode");
      codeHtml.innerHTML = "";
      this.imgSrc = "";
    },
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
.confirmAdd {
  background: #637dff;
  color: #fff;
}

.top-up {
  margin-left: 1.875rem;
}

.topUp-tag-out {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.titleno {
  margin-bottom: 0.625rem;
}

.topUp-tag {
  width: 6.25rem;
  height: 3.5rem;
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  border-radius: 0.375rem;
  cursor: pointer;
}

.isSelect {
  color: #fff;
  background: #637dff;
}

.unSelect {
  color: #323543;
  background: #fff;
  border: 1px solid #e0e3f1;
}

.topUp-code-title {
  width: 100%;
  text-align: center;
  padding: 0.3rem 0;
  font-size: 1.75rem;
  font-weight: 600;
}

.topUp-code-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.125rem 0;
}

.poundage {
  width: 260px;
  border-top: 1px solid #ebeef5;
}

.poundage-name {
  color: #909399;
}

.poundage-val {
  color: #303133;
  font-weight: 600;
}
</style>