<template>
  <div class="m3-flex-col">

    <a-table class="m3-width-full"
             :dataSource="list" :columns="tableColumns"
             :scroll="{ x: 1400 }"
             :loading="listLoading" size="small"
             :pagination="pagination">
      <template #openid="{record}">
        <div v-if="record.openid" class="m3-flex">
          <span class="m3-padding-horizontal-xs">已绑定</span>
          <a-popconfirm
              title="确定解绑微信用户?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="unBindWechat(record)">
            <a-button type="primary" size="small" danger>解绑</a-button>
          </a-popconfirm>
        </div>
        <div v-else>
          <a-button type="primary" size="small" @click="switchBindWechatDialog(record)">绑定微信用户</a-button>
        </div>
      </template>
      <template #balanceNotice="{record}">
        <a-switch v-model:checked="record.balanceNotice"
                  :checked-value="1" :un-checked-value="0"
                  :disabled="record.disabled"
                  @change="noticeChange(record)"/>
      </template>
      <template #checkNotice="{record}">
        <a-switch v-model:checked="record.checkNotice"
                  :checked-value="1" :un-checked-value="0"
                  :disabled="record.disabled"
                  @change="noticeChange(record)"/>
      </template>
      <template #checkOrderNotice="{record}">
        <a-switch v-model:checked="record.checkOrderNotice"
                  :checked-value="1" :un-checked-value="0"
                  :disabled="record.disabled"
                  @change="noticeChange(record)"/>
      </template>
      <template #customHandle>
        <div>操作
          <!--          <a-button type="link" @click="editUser(null)">新增</a-button>-->
          <!--          <a-button type="link" @click="getUserList">刷新</a-button>-->
        </div>
      </template>
      <template #handle="{record}">
        <a-button type="primary" @click="editUser(record)" size="small">编辑</a-button>
        <a-popconfirm
            title="确定删除此条数据?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deleteUser(record)">
          <a-button class="m3-margin-left-s" type="primary" size="small" danger>删除</a-button>
        </a-popconfirm>
      </template>

    </a-table>

    <EditUserDialog v-model:dialog-visible="editUserDialogVisible"
                    :record="currentRecord"
                    @refresh="refreshList"
                    @confirm="editUserDialogConfirm"/>
    <!--    <BindWechatDialog v-model:dialog-visible="bindWechatDialogVisible" :record="currentRecord"-->
    <!--                      @confirm="bindWechatDialogConfirm"></BindWechatDialog>-->

    <BindWechatDialog v-model:dialog-visible="bindWechatDialogVisible" :uid="currentUid" @refresh="refreshList"/>
  </div>
</template>

<script>
import {onMounted, reactive, ref, toRaw} from "vue";
import {safePromise} from "@/utils/common/safePromise";
import wechatApi from "@/api/open/wechat/wechatApi";
import AButton from "ant-design-vue/lib/button/button";
import EditUserDialog from "@/views/web/personal/userList/EditUserDialog.vue";
import contactApi from "@/api/web/contactApi";
import {message} from "ant-design-vue";
import BindWechatDialog from "@/views/web/personal/userList/BindWechatDialog.vue";

export default {
  name: "UserList",
  components: {BindWechatDialog, EditUserDialog, AButton},
  setup(props, context) {

    let list = ref([]);
    let listLoading = ref(false);

    let editUserDialogVisible = ref(false);
    let bindWechatDialogVisible = ref(false);
    let currentUid = ref('');

    // 获取微信用户列表
    let getUserList = async (needLoading = true) => {
      if (needLoading) {
        listLoading.value = true;
      }
      let paginationParams = {
        page: pagination.current,
        pageSize: pagination.pageSize,
      };
      let res = await safePromise(contactApi.userList(paginationParams));
      list.value = res?.data || [];
      pagination.total = list.value?.length || 0;
      listLoading.value = false;
    };

    let pagination = reactive({
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["10", "20", "30", "40"],
      // 改变每页数量时更新显示
      onShowSizeChange: (current, pageSize) => {
        // console.log('onShowSizeChange', current, pageSize)
        pagination.current = current;
        pagination.pageSize = pageSize;
        getUserList();
      },
      // 改变页数，跳转页数
      onChange: (current, pageSize) => {
        // console.log('onChange', current, pageSize)
        pagination.current = current;
        pagination.pageSize = pageSize;
        getUserList();
      },
    });

    let tableColumns = [
      {
        title: '姓名',
        key: 'name',
        dataIndex: 'name',
        slots: {customRender: 'name'},
      },
      {
        title: '手机号码',
        key: 'phone',
        dataIndex: 'phone',
        slots: {customRender: 'phone'},
      },
      {
        title: '联系人邮箱',
        key: 'email',
        dataIndex: 'email',
        slots: {customRender: 'email'},
      },
      {
        title: '绑定微信用户',
        key: 'openid',
        dataIndex: 'openid',
        slots: {customRender: 'openid'},
      },
      {
        title: '余额不足通知',
        key: 'balanceNotice',
        dataIndex: 'balanceNotice',
        slots: {customRender: 'balanceNotice'},
      },
      {
        title: '质检风险通知',
        key: 'checkNotice',
        dataIndex: 'checkNotice',
        slots: {customRender: 'checkNotice'},
      },
      {
        title: '线路质检违规通知',
        key: 'checkOrderNotice',
        dataIndex: 'checkOrderNotice',
        slots: {customRender: 'checkOrderNotice'},
      },
      {
        key: 'handle',
        dataIndex: 'handle',
        width: '200px',
        fixed: 'right',
        slots: {title: 'customHandle', customRender: 'handle'},
      },
    ];

    let switchBindWechatDialog = (record) => {
      currentUid.value = record.id;//注意这里是联系人id
      bindWechatDialogVisible.value = !bindWechatDialogVisible.value;
    }

    let unBindWechat = async (record) => {
      let res = await safePromise(contactApi.unBindWechat({id: record.id}));
      if (res?.code == 200) {
        message.success('解绑成功');
        record.openid = null;
      }
    }

    let refreshList = async (needRefresh) => {
      if (needRefresh) {
        await getUserList(false);
        if (currentRecord.value && currentRecord.value.id) {
          //刷新列表后，如果编辑弹窗打开了，需要刷新一次当前编辑框里的数据，主要是绑定了用户微信，这时有了openid。
          let find = list.value.find(item => item.id == currentRecord.value.id);
          if (find) {
            currentRecord.value = find;
          }
        }
      }
    }

    let noticeChange = async (record) => {
      listLoading.value = true;
      record.disabled = true;
      let params = toRaw(record);
      // console.log('noticeChange', params);
      let res = await safePromise(contactApi.editUser(params));
      if (!res || res?.code != 200) {
        message.error(res?.message || '提交失败');
      }
      record.disabled = false;
      listLoading.value = false;
    }

    let currentRecord = ref({});
    let editUser = async (record) => {
      currentRecord.value = record || {};
      editUserDialogVisible.value = true;
      // console.log('editUser', record, currentRecord.value, editUserDialogVisible.value)
    }
    let deleteUser = async (record) => {
      listLoading.value = true;
      let res = await safePromise(contactApi.deleteUser({id: record.id}));
      listLoading.value = false;
      if (res?.code == 200) {
        let findIndex = list.value.findIndex(item => item.id == record.id);
        if (findIndex !== -1) {
          list.value.splice(findIndex, 1);
        }
      }
    }

    let editUserDialogConfirm = (record) => {
      console.log('dialogConfirm record', record);
      if (record?.id) {
        let findIndex = list.value.findIndex(item => item.id == record?.id);
        if (findIndex !== -1) {
          //编辑
          list.value.splice(findIndex, 1, record);
        } else {
          //新增
          list.value.splice(0, 0, record);
        }
      } else {
        // 如果没有id，则刷新列表
        getUserList(true);
      }
    }

    onMounted(() => {
      getUserList();
    })

    return {
      pagination,
      list, listLoading, tableColumns,
      noticeChange, editUser, deleteUser, getUserList, unBindWechat,
      currentRecord, editUserDialogVisible, editUserDialogConfirm,
      bindWechatDialogVisible, currentUid, switchBindWechatDialog,
      refreshList,
    }
  },
}
</script>

<style scoped>

</style>