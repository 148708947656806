// 用户接口管理
import { Request } from '@/http/request'

// 管理员登陆
export function loginUserData (parameter: any) {
  return Request.axiosInstance.post('/open/api-info/info', parameter)
}
// 生成需要的密钥
export function generateUserData (parameter: any) {
    return Request.axiosInstance.post('/open/api-info/generate', parameter)
}
// 接口信息修改 
export function editUserData (parameter: any) {
    return Request.axiosInstance.post('/open/api-info/edit', parameter)
}
// 接口信息添加
export function addUserData (parameter: any) {
    return Request.axiosInstance.post('/open/api-info/add', parameter)
}
// 接口信息查询
export function addUserDataQuery(parameter: any) {
    return Request.axiosInstance.post('/open/api-info/query', parameter)
}

export default { loginUserData, generateUserData, editUserData, addUserData, addUserDataQuery }