// 个人中心
<template>
  <div class="personnalBox">
    <el-row>
      <el-col :span="24">
        <div class="on-information">
          <!-- <a-tabs v-model:activeKey="activeKey" class="form" @change="tabChange"> -->
          <a-tabs v-model:activeKey="activeKey" class="form">
            <!-- 基本信息 -->
            <a-tab-pane key="1">
              <template #tab>
                <span>基本信息</span>
              </template>
              <el-row>
                <el-col :span="4">
                  <div class="photo-box">
                    <div>
                      <div class="block">
                        <!-- <el-avatar :size="100" :src="informationList.avatar"></el-avatar> -->
                        <div>
                          <imgUp1 :width="90" :height="90" :type="'1'" :kind="'web'" ops="0" index='0'
                                  :img="informationList.avatar" @success="handleImgSuccess"
                                  @delete="handleImgDelete"></imgUp1>
                        </div>
                        <div v-if="openInput == true" class="user-name">
                          <span>{{ informationList.nickname }}</span>
                          <!-- <icon-font type="icon-copy-4-04" class="img-icon" @click="openInputs"/> -->
                        </div>
                        <div v-if="openInput == false" class="user-name">
                          <el-input v-model="input" placeholder="请输入内容"></el-input>
                          <i class="el-icon-loading" v-if="iconLoading == true"></i>
                          <i class="el-icon-circle-check img-icon" v-else @click="modifyUp"></i>
                          <!-- <icon-font type="icon-copy-4-04" v-else class="img-icon" @click="modifyUp"/> -->

                        </div>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="20">
                  <div class="outBox">
                    <div class="titleLine">
                      <span>登录账号：</span><span class="text-in">{{ informationList.phone }}</span>
                    </div>
                    <div class="titleLine">
                      <span>认证类型：</span>
                      <el-tag effect="plain" class="text-in">{{ informationList.certTypeText }}</el-tag>
                    </div>
                    <div class="titleLine">
                      <span>用户类型：</span><span class="text-in">{{ informationList.level.name }}</span>
                    </div>
                    <div class="titleLine">
                      <span>注册时间：</span><span class="text-in">{{ informationList.createTime }}</span>
                    </div>
                    <div class="titleLine">
                      <span>信用额度：</span><span class="text-in">{{ informationList.quota }}元</span>
                    </div>
                    <div class="titleLine">
                      <span>账户余额：</span><span class="text-in">{{ informationList.balance }}元</span>
                      <buy ref="buy"/>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="un-information">
                    <a-tabs v-model:activeKey="activeKeyOther" class="form">
                      <!-- 应用信息 -->
                      <a-tab-pane key="1">
                        <template #tab>
                          <span>应用信息</span>
                        </template>
                        <div class="outBox">
                          <el-form label-position="left" label-width="100px" :model="ueserData" :rules="rules"
                                   ref="ueserDataFrom">
                            <el-row :gutter="20">
                              <el-col :span="24">
                                <el-form-item label="accessKey：">
                                  <div class="fromData flex-start">
                                    <div class="fromData-in">
                                      <el-input
                                          placeholder="请输入内容"
                                          v-model="ueserData.accessKey"
                                          :disabled="true">
                                      </el-input>
                                    </div>
                                    <el-button class="confirmAdd tag-read-other"
                                               :data-clipboard-text="ueserData.accessKey"
                                               @click="copy('.tag-read-other')">复制
                                    </el-button>
                                  </div>
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <el-row :gutter="20">
                              <el-col :span="24">
                                <el-form-item label="accessSerect：">
                                  <div class="fromData flex-start">
                                    <div class="fromData-in">
                                      <el-input
                                          placeholder="请输入内容"
                                          v-model="ueserData.accessSerect"
                                          :disabled="true">
                                      </el-input>
                                    </div>
                                    <el-button class="confirmAdd tag-read" :data-clipboard-text="ueserData.accessSerect"
                                               @click="copy('.tag-read')">复制
                                    </el-button>
                                  </div>
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <el-row :gutter="20">
                              <el-col :span="24">
                                <el-form-item label="">
                                  <div class="fromData">
                                    <!-- <div class="fromData" v-if="submitType == 'edit'"> -->
                                    <!-- <el-button class="confirmAdd" round @click="generate">生成密钥</el-button> -->
                                  </div>
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <el-row :gutter="20">
                              <el-col :span="24">
                                <el-form-item label="回调地址：">
                                  <div class="fromData">
                                    <el-input v-model="ueserData.notifyUrl" placeholder="请输入回调地址"></el-input>
                                  </div>
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <el-row :gutter="20">
                              <el-col :span="24">
                                <el-form-item label="IP白名单：">
                                  <div class="fromData">
                                    <el-input v-model="ueserData.whiteIp"
                                              placeholder="请输入IP白名单(多个以','分隔)"></el-input>
                                  </div>
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <el-row :gutter="20">
                              <el-col :span="24">
                                <el-form-item label="">
                                  <div class="fromData">
                                    <el-button class="cancel" plain  @click="resetUserForm('ueserDataFrom')">取消
                                    </el-button>
                                    <el-button class="confirmAdd"  @click="submitUserForm('ueserDataFrom')">保存
                                    </el-button>
                                  </div>
                                </el-form-item>
                              </el-col>
                            </el-row>
                          </el-form>
                        </div>
                      </a-tab-pane>

                      <!-- 认证信息 -->
                      <a-tab-pane key="2">
                        <template #tab>
                          <span>认证信息</span>
                        </template>
                        <div v-if="certification==0">
                          <div class="outBox">
                            <div class="titleLine">
                              <span>认证种类：</span>
                              <div class="text-in">
                                <el-radio-group v-model="approveType">
                                  <el-radio :label="1">个人认证</el-radio>
                                  <el-radio :label="2">企业认证</el-radio>
                                </el-radio-group>
                              </div>
                            </div>
                          </div>
                          <!-- 个人认证 -->
                          <div v-if="approveType == 1" class="outBox">
                            <el-form label-position="left" label-width="100px" :model="personalFrom" :rules="rules"
                                     ref="personalFrom">
                              <el-row :gutter="20">
                                <el-col :span="24">
                                  <el-form-item label="姓名：" prop="name">
                                    <div class="fromData">
                                      <el-input v-model="personalFrom.name" placeholder="请输入您的姓名"></el-input>
                                    </div>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                              <el-row :gutter="20">
                                <el-col :span="24">
                                  <el-form-item label="身份证：" prop="certNo">
                                    <div class="fromData">
                                      <el-input v-model="personalFrom.certNo"
                                                placeholder="请输入您的身份证账号"></el-input>
                                    </div>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                              <el-row :gutter="20">
                                <el-col :span="24">
                                  <el-form-item label="">
                                    <div class="fromData">
                                      <el-button class="cancel" plain round @click="resetForm('personalFrom')">取消
                                      </el-button>
                                      <el-button class="confirmAdd" round @click="submitForm('personalFrom')">提交认证
                                      </el-button>
                                    </div>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                            </el-form>
                          </div>
                          <!-- 企业认证 -->
                          <div v-else class="outBox">
                            <el-form label-position="left" label-width="130px" :model="enterpriseFrom" :rules="rules"
                                     ref="enterpriseFrom">
                              <el-row :gutter="20">
                                <el-col :span="24">
                                  <el-form-item label="企业名称：" prop="enterpriseName">
                                    <div class="fromData">
                                      <el-input v-model="enterpriseFrom.enterpriseName"
                                                placeholder="请输入企业名称"></el-input>
                                    </div>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                              <el-row :gutter="20">
                                <el-col :span="24">
                                  <el-form-item label="法人名称：" prop="person">
                                    <div class="fromData">
                                      <el-input v-model="enterpriseFrom.person" placeholder="请输入法人名称"></el-input>
                                    </div>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                              <el-row :gutter="20">
                                <el-col :span="24">
                                  <el-form-item label="社会信用代码：" prop="regNum">
                                    <div class="fromData">
                                      <el-input v-model="enterpriseFrom.regNum"
                                                placeholder="请输入社会信用代码"></el-input>
                                    </div>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                              <el-row :gutter="20">
                                <el-col :span="24">
                                  <el-form-item label="营业执照：" prop="imgUrl">
                                    <div class="fromData">
                                      <imgUp2 :width="360" :height="160" :type="'1'" :kind="'web'" ops="1" index='1'
                                              :img="enterpriseFrom.imgUrl" @success="handleImgSuccess"
                                              @delete="handleImgDelete"></imgUp2>
                                      <p>请上传企业的营业执照</p>
                                    </div>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                              <el-row :gutter="20">
                                <el-col :span="24">
                                  <el-form-item label="">
                                    <div class="fromData">
                                      <el-button class="cancel" plain round
                                                 @click="resetEnterpriseForm('enterpriseFrom')">取消
                                      </el-button>
                                      <el-button class="confirmAdd" round
                                                 @click="submitEnterpriseForm('enterpriseFrom')">
                                        提交认证
                                      </el-button>
                                    </div>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                            </el-form>
                          </div>
                        </div>
                        <div class="certification-box" v-if="certification == 1">
                          <!-- 已认证{{certification}} -->
                          <div class="approve-end">
                            <img class="approve-img" :src="require(`@/assets/images/approve.png`)" alt="">
                            <div class="approve-text">
                              <div class="titleLine">
                                <span class="titles">恭喜，您已完成实名认证</span>
                              </div>
                              <div class="titleLine">
                                <span>认证类型：</span><span class="text-in">{{ selfInfo?.typeText }}</span>
                              </div>
                              <div class="titleLine">
                                <span>{{ selfInfo?.type == 2 ? '企业名称' : '姓名' }}：</span><span
                                  class="text-in">{{ selfInfo?.name }}</span>
                              </div>
                              <div class="titleLine">
                                <span>认证时间：</span><span class="text-in">{{ selfInfo?.createTime }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="certification-box" v-if="certification == 2">
                          <!-- 已认证{{certification}} -->
                          <div class="approve-end">
                            <img class="approve-img" :src="require(`@/assets/images/approve.png`)" alt="">
                            <div class="approve-text">
                              <div class="titleLine">
                                <span class="titles">已提交！请等待审核。</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a-tab-pane>
                    </a-tabs>
                  </div>
                </el-col>
              </el-row>
            </a-tab-pane>
            <a-tab-pane key="2">
              <template #tab>
                <span>联系人管理</span>
              </template>
              <UserList ref="userList"></UserList>
            </a-tab-pane>
            <a-tab-pane key="3">
              <template #tab>
                <span>余额</span>
              </template>
              <el-row>
                <el-col :span="20">
                  <div class="outBox1">
                    <div class="titleLine">
                      <span>账户余额：</span><span class="text-in">{{ informationList.balance }}元</span>
                      <buy ref="buy"/>
                    </div>
                    <div class="titleLine">
                      <span>余额提醒：</span><span class="text-in">
                        <el-switch
                                    active-value="1"
                                    inactive-value="0"
                                    v-model="valuePriceForm.optionValue"
                                    @click="changepic(valuePriceForm)"
                                   >
                                  </el-switch>
                          </span>
                    </div>
                    <div class="titleLine">
                      <span>提醒数值：</span><span class="text-in">{{ numPrice1Form.optionValue}}元&nbsp;</span>
                      <el-button class="top-up confirmAdd" @click="reminderSettings" :disabled="valuePriceForm.optionValue==1?false:true" :class="{ 'disabled-button': valuePriceForm.optionValue==1?false:true }">设置</el-button>
                    </div>
                  </div>
                </el-col>
              </el-row>
             
            </a-tab-pane>
            <template #tabBarExtraContent v-if="activeKey==2">
              <a-button type="primary" class="m3-margin-left-s" @click="addUser">新增</a-button>
              <a-button type="primary" class="m3-margin-left-s" @click="refreshUserList">刷新</a-button>
            </template>
          </a-tabs>
        </div>
      </el-col>
    </el-row>



    <!-- 设置弹窗 -->
    <el-dialog title="余额提醒" v-model="dialogVirtualVisible" :show-close="false" :close-on-click-modal="false"
      :destroy-on-close="true" width="40%">
      <div>
        当您的余额低于<el-input-number v-model="numPrice1Form.optionValue
        
        " @change="handleChange" :min="500" :max="1000000" :step="100"></el-input-number>元时，
        系统将发起短信提醒和消息提醒
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVirtualVisible = false;virtualName=''">取消</el-button>
          <el-button type="primary" @click="handleVirtualAdd">确认</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 弹窗 -->
    <el-dialog
        title="扫码认证"
        v-model="centerDialogVisible"
        :destroy-on-close="true"
        width="30%"
        @close='closeDialog'>
      <div class="code-title">请使用支付宝扫码二维码完成认证</div>
      <div class="code-box">
        <setQrcode :text="link" :src="imgSrc" :width="200" :height="200" :size="90"></setQrcode>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {IconFont} from "@/utils/iconfont"
import approve from '@/api/web/approve'
import {getConfiguration,modifyConfiguration} from '@/api/web/configuration'
import user from '@/api/web/user'
import apiInfo from '@/api/open/user/apiInfo'
import {isValidIp} from '@/utils/filter'
import Clipboard from 'clipboard'
import imgUp1 from '@/components/imgUpLoad'
import imgUp2 from '@/components/imgUpLoad'
import topUp from '@/components/topUp'
import emitter from '@/utils/bus.js';
import webUser from '@/api/web/user'
import store from '@/store'
import setQrcode from '@/components/setQrcode.vue';
import {Request} from '@/http/request'
import wechatApi, {wxUserList} from "@/api/open/wechat/wechatApi";
import {safePromise} from "@/utils/common/safePromise";
import UserList from "@/views/web/personal/userList/UserList.vue";


export default {
  name: "qrCode",
  components: {
    UserList,
    IconFont,
    buy: topUp,
    imgUp1,
    imgUp2,
    setQrcode
  },
  data() {
    const validIp = (rule, value, callback) => {
      if (!isValidIp(value)) {
        return callback(new Error('请输入节点ip地址，多节点用,分隔'))
      } else {
        callback()
      }
    }
    const validHttp = (rule, value, callback) => {
      let httpRule = /(http|https):\/\/([\w.]+\/?)\S*/
      // for (var i in ipList) {
      if (!httpRule.test(value)) {
        return callback(new Error('请输入正确的回调地址'))
      } else {
        callback()
      }
    }
    return {
      numPrice1Form:{},
      valuePriceForm:{},
      dialogVirtualVisible: false,
      numPrice:500,
      numPrice1:'',
      valuePrice:null,
      numPricebtn:false,
      getConfig:{
        optionKey:'',
        optionType:''
      },
      activeKey: '1',
      activeKeyOther: '1',//底部切换
      circleUrl: '',//头像
      openInput: true,// 点击修改名称
      input: '',//修改用户名
      approveType: 1,//切换认证种类
      centerDialogVisible: false,
      informationList: {
        nickname: '-',
        phone: '-',
        certTypeText: '-',
        level: {
          name: '-'
        },
        createTime: '-'
      },
      certification: 0,//0 未认证 1 已认证
      iconLoading: false,
      // =========== 应用信息 ====================
      submitType: 'add',
      ueserData: {
        accessKey: '填写完整资料点击生成',
        accessSerect: '填写完整资料点击生成',
        notifyUrl: '',
        whiteIp: ''
      },
      // =========== 企业认证 ====================
      enterpriseFrom: {
        enterpriseName: '',//企业名称
        person: '',//法人名
        imgUrl: '',//营业执照
        regNum: ''//社会信用代码
      },
      // =========== 个人认证 ====================
      personalFrom: {
        name: '',
        certNo: ''
      },
      show: [],
      link: '',
      imgSrc: '',
      // =========== 认证信息 ====================
      selfInfo: {},
      rules: {
        name: [
          {required: true, message: '请输入正确的姓名', trigger: 'blur'},
          {min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur'}
        ],
        certNo: [
          {required: true, message: '请输入正确的身份证号码', trigger: 'blur'},
          {min: 18, max: 18, message: '请输入正确长度的身份证号码', trigger: 'blur'}
        ],
        notifyUrl: [
          {required: true, message: '请输入回调地址', trigger: 'blur'},
          {validator: validHttp, trigger: 'blur'},
        ],
        whiteIp: [
          {validator: validIp, trigger: 'blur'}
        ],
        enterpriseName: [
          {required: true, message: '请输入企业名称', trigger: 'blur'},
        ],
        person: [
          {required: true, message: '请输入法人名称', trigger: 'blur'},
        ],
        regNum: [
          {required: true, message: '请输入社会信用代码', trigger: 'blur'},
        ],
        imgUrl: [
          {required: true, message: '请输上传营业执照', trigger: 'blur'},
        ],
      },
    }
  },
  created() {
this.changePrice()
  },
  watch: {},
  mounted() {
    // console.log('this.$store', this.$store)
    this.getLoginUserData()
    this.setNewInfo()
    this.certification = this.$store.getters.user.certType
    this.getSelfInfo(this.certification)
    emitter.on('getTarget', (target) => {
      this.setNewInfo()
    });
    emitter.on('results', (target) => {
      if (target == 'ok') {
        this.imgSrc = 'ok'
        this.certification = 1
        // this.setSelfInfo ()
        this.getSelfInfo(this.certification)
        // this.setInformationList(this.$store.getters.userType)
      }
    });
  },
  methods: {
    changepic(e){

  


      modifyConfiguration({...e}).then(res=>{
   console.log(res.code,'88888888888888888888888888888888');
       if (res.code==200) {
        
        this.$notify({
                    title: '成功',
                    message: '设置成功' ,
                    type: 'success'
                });
              
        }else{
          this.$notify({
                    title: '失败',
                    message: '设置失败' ,
                    type: 'warning'
                });
        }
      })


    },
    //提醒设置
    reminderSettings(){
      console.log('666');
      this.dialogVirtualVisible=true
      
    },
    handleVirtualAdd(){
    this.changepic(this.numPrice1Form)
    this.dialogVirtualVisible=false
   
    },
    changePrice() {
      getConfiguration(
       {
        optionKey:'enable',
        optionType:"balanceWarning"
       }).then((response) => {
    this.valuePriceForm=response.data
    this.changePrice1()
  })
 
  
},
changePrice1() {
      getConfiguration(
       {
        optionKey:'threshold',
        optionType:"balanceWarning"
       }).then((response) => {
        // this.numPrice1 = response.data.optionValue;
        this.numPrice1Form=response.data
       this.numPrice1Form.optionValue=parseInt(this.numPrice1Form.optionValue)
 
  });
  
},
    addUser(){
      this.$refs['userList']?.editUser(null);
    },
    refreshUserList(){
      this.$refs['userList']?.getUserList();
    },
    handleFileChange(e) {
      console.log(e,'000000000000000000000000000000000000');
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      img.imgWebUpload(formData).then(res => {
        if (res) {
          this.informationList.avatar = res.data.url
        }
      })
      e.target.value = ''
    },
    onDeleteClick() {
      this.$emit('delete', this.ops)
    },
    onEnter() {
      this.show = ['show']
    },
    onLeave() {
      this.show = []
    },

    async setSelfInfo() {
      await this.$store.dispatch('modifyUser', this.$store.getters.userType).then(() => {
        this.getSelfInfo(this.certification)
        // this.informationList = this.$store.state.user
      })
    },
    setInformationList(userType) {
      if (userType == 'system') {
        Request.axiosInstance.post('/system/admin-user/info').then((res) => {
          this.informationList = res.data
          this.$store.dispatch('setUser', res.data)
        })
      }
      // 注册用户重设
      if (userType == 'web') {
        Request.axiosInstance.post('/web/user/info').then((res) => {
          this.informationList = res.data
          this.$store.dispatch('setUser', res.data)
        })
      }
    },
    tryreset() {
      this.imgSrc = 'err'
    },
    trysubmit() {
      this.imgSrc = 'ok'
    },
    back() {
      this.imgSrc = ''
    },
    // ============ 公共处理方法 ================
    openInputs() {
      this.openInput = false
      this.iconLoading = false
    },
    modifyUp() {
      this.iconLoading = true
      webUser.editData({
        uid: this.$store.getters.user.uid,
        nickname: this.input
      }).then((res) => {
        this.iconLoading = false
        this.setNewInfo()
        this.openInput = true
      }).catch((err) => {
        this.openInput = true
      })
    },
    // 头像上传
    handleImgSuccess_avatar(val) {
      this.informationList.avatar = val[0]
      webUser.editData({
        uid: this.$store.getters.user.uid,
        avatar: val[0],
        phone: this.$store.getters.user.phone,
      }).then((res) => {
        this.setNewInfo()
      }).catch((err) => {

      })
    },
    // 头像上传
    handleImgSuccess_avatar1(val) {
      console.log(val,);
      this.informationList.avatar1 = val
    },
    handleImgDelete_avatar() {
      this.informationList.avatar = null
    },
    copy(isClass) {
      var clipboard = new Clipboard(isClass)
      clipboard.on('success', e => {

      })
      clipboard.on('error', e => {
        // 不支持复制
        console.log('该浏览器不支持复制')
        // 释放内存
        // clipboard.destory()
      })
    },
    // 更新store里的相关数据
    setNewInfo() {
      user.getInfo().then((res) => {
        this.informationList = res.data
        store.dispatch('setUser', res.data)
        this.input = this.informationList.nickname
      })
    },
    // =========== 初始化请求 ====================

    // =========== 应用信息 ====================
    getLoginUserData() {
      apiInfo.loginUserData({}).then((res) => {
        if (res.data != undefined) {
          this.ueserData = res.data
          this.submitType = 'edit'
        } else {
          this.submitType = 'add'
        }
      })
    },
    // 生成密钥
    generate() {
      apiInfo.generateUserData({}).then((res) => {
        this.ueserData = res.data
      })
    },
    // 表单提交
    submitUserForm(formName) {
      this.$refs[formName].validate((valid) => {
        console.log('submitUserForm', valid)
        if (valid) {
          // if(this.submitType == 'add'){
          //     apiInfo.addUserData({
          //         notifyUrl:this.ueserData.notifyUrl,
          //         whiteIp:this.ueserData.whiteIp
          //     }).then((res) => {
          //         this.ueserData = res.data
          //         this.submitType = 'edit'
          //         this.$notify({
          //             title: '保存成功',
          //             message: '已生成对应的密钥',
          //             type: 'success'
          //         });
          //     })
          // }else{
          apiInfo.editUserData({
            id: this.ueserData.id,
            notifyUrl: this.ueserData.notifyUrl,
            whiteIp: this.ueserData.whiteIp
          }).then((res) => {
            // this.ueserData = res.data
            // this.submitType = 'edit'
            this.$notify({
              title: '修改成功',
              message: '配置已修改',
              type: 'success'
            });
          })
          // }
        } else {
          return false;
        }
      });
    },
    resetUserForm(formName) {
      this.$refs[formName].resetFields();
    },
    // =========== 个人认证 ====================
    // 表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let material = {
            certNo: ''
          };
          material.certNo = this.personalFrom.certNo
          approve.ersonalFace({
            type: 1,//1 个人，2 企业
            name: this.personalFrom.name,
            material: JSON.stringify(material)
          }).then((res) => {
            this.link = res.data
            this.centerDialogVisible = true
          })
        } else {
          return false;
        }
      });
    },
    // 关闭弹窗清除二维码
    closeDialog() {
      let codeHtml = document.getElementById("qrCode")
      codeHtml.innerHTML = ""
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // =========== 企业认证 ====================
    // 表单提交
    submitEnterpriseForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let material = {
            person: '',
            regNum: '',
            imgUrl: ''
          };
          material.person = this.enterpriseFrom.person
          material.regNum = this.enterpriseFrom.regNum
          material.imgUrl = this.enterpriseFrom.imgUrl
          approve.firm({
            type: 2,//1 个人，2 企业
            name: this.enterpriseFrom.enterpriseName,
            material: JSON.stringify(material)
          }).then((res) => {
            this.certification = 2
          })
        } else {
          return false;
        }
      });
    },
    resetEnterpriseForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 图片上传
    handleImgSuccess(val) {
      console.log('handleImgSuccess', val)
      if (val[1] == 0) {
        this.informationList.avatar = val[0]
        webUser.editData({
          uid: this.$store.getters.user.uid,
          avatar: val[0],
          phone: this.$store.getters.user.phone,
        }).then((res) => {
          // this.setNewInfo()
        }).catch((err) => {

        })
      } else {
        this.enterpriseFrom.imgUrl = val[0]
      }

    },
    handleImgDelete(val) {
      if (val == 0) {
        this.informationList.avatar = null
      } else {
        this.enterpriseFrom.imgUrl = null
      }

    },
    // =========== 认证信息 ==================
    getSelfInfo(type) {
      if (type != 0) {
        approve.selfInfo({}).then((res) => {
          // console.log(res)
          this.selfInfo = res.data
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.personnalBox {
  height: calc(100vh - 135px);
  overflow-y: overlay;
  padding-right: 18px;
}

.photo-box {
  width: 100%;
  height: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-name {
  margin-top: .6rem;
  width: 6.25rem;
  text-align: center;
}

.img-icon {
  cursor: pointer;
  color: #7594FF;
  font-size: 1.3rem;
}

.outBox {
  padding: 1.875rem 1.875rem 0 1.875rem;
  width: 55.625rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.outBox1{
  padding: 1.875rem 1.875rem 0 1.875rem;
  width: 55.625rem;
 
}

.titleLine {
  width: 18.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-items: center;
}

.text-in {
  margin-left: 1.875rem;
}

.fromData {
  width: 22.5rem;
}

.fromData-in {
  width: 18.125rem;
}

.flex-start {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.confirmAdd {
  background: #637DFF;
  color: #fff;
}

.cancel {
  color: #637DFF;
  border: #637DFF solid 1px;
}

.code-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.125rem 0;
}

.code-title {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
}

.certification-box {
  padding: 1.6rem;
}

.approve-end {
  height: 50%;
  display: flex;
  justify-items: flex-start;
  align-items: center;
}

.approve-img {
  width: 21rem;
  height: 17.5rem;
}

.titles {
  font-size: 1.25rem;
  font-weight: 600;
}

.approve-text {
  margin-left: 1.875rem;
}

.show {
  display: flex !important;
}

.upload {
  display: flex;
  background-color: #fefefe;
  border-radius: 10px;
  position: relative;
}

.upload-icon:hover {
  border-color: #409EFF;
}

.upload-icon {
  width: 100%;
  height: 100%;
  border: 1px dashed #ddd;
  border-radius: 5px;
}

.uplpad-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uplpad-content:hover {
  cursor: pointer;
}

.el-icon-plus {
  font-size: 24px;
  color: #999;
}

.img-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.el-image {
  width: 100%;
  height: 100%;
}

.avatar-box {
  border: solid 1px #303133;
}

.mask {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.el-icon-delete {
  color: #ededed;
  font-size: 22px;
  cursor: pointer;
}

.el-icon-thumb {
  color: #ededed;
  font-size: 22px;
  cursor: pointer;
}
.disabled-button {
  /* 这里是禁用状态下的样式 */
  opacity: 0.5;
  cursor: not-allowed;
  /* 其他样式属性 */
   background-color: white;
  color: rgb(174, 174, 174);
}
</style>