// 实名认证
import { Request } from '@/http/request'

// 人脸身份认证申请
export function ersonalFace (parameter: any) {
  return Request.axiosInstance.post('/web/user-auth/personal-face', parameter)
}
// 企业认证
export function firm (parameter: any) {
  return Request.axiosInstance.post('/web/user-auth/firm', parameter)
}
// 认证信息
export function selfInfo (parameter: any) {
  return Request.axiosInstance.post('/web/user-auth/info', parameter)
}
// 获取认证信息
export function user (parameter: any) {
  return Request.axiosInstance.post('/web/user-auth/user', parameter)
}

export default { ersonalFace, firm, selfInfo, user }